/****************
 * Phone mask example
 ****************/

window.addEventListener('load', () => {
  const maskedPhonesInput = document.querySelectorAll('input[type="tel"], .phone-mask');
  if (maskedPhonesInput) {
    const maskOptions = {
      mask: '+{7} (000) 000-00-00',
    };
    [...maskedPhonesInput].map((el) => {
      const maskedInput = IMask(el, maskOptions);
      el.addEventListener('keydown', () => {
        if (maskedInput.unmaskedValue === '78') {
          maskedInput.value = '+7 (';
        }
      });
      // simple validation
      el.addEventListener('keyup', () => {
        if (maskedInput.unmaskedValue.length === 11) {
          maskedInput.el.input.classList.add('input_valid');
          maskedInput.el.input.classList.remove('input_invalid');
        }
        else {
          maskedInput.el.input.classList.add('input_invalid');
          maskedInput.el.input.classList.remove('input_valid');
        }
      });
    });
  }
});

window.addEventListener('load', () => {
  // empty links
  const emptyLinks = document.querySelectorAll('a[href="#"]');
  [...emptyLinks].map(link => link.addEventListener('click', (e) => {
    e.preventDefault();
  }));

  //sidebar catalog accordion
  const sidebarCatalog = document.querySelector('.sidebar-catalog__content');
  if (sidebarCatalog) {
    const sections = sidebarCatalog.querySelectorAll('span');
    [...sections].map(title => title.addEventListener('click', (e) => {
      const parent = title.parentNode;
      if (parent.classList.contains('active')) {
        parent.classList.remove('active');
        return true;
      }
      const activeLi = sidebarCatalog.querySelector('li.active');
      if (activeLi) {
        activeLi.classList.remove('active');
      }
      parent.classList.add('active');
      return true;
    }))
  }

  // sidebar brads
  const sidebarBrands = document.querySelector('.sidebar-brands');
  if (sidebarBrands) {
    const title = sidebarBrands.querySelector('.sidebar-brands__title');
    title.addEventListener('click', (e) => {
      e.preventDefault();
      sidebarBrands.classList.toggle('active');
    })
  }

  // calc scroll width
  document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + "px");

  // mobile menu
  const burger = document.getElementById('main-menu__burger');
  if (burger) {
    burger.addEventListener('click', (e) => {
      e.target.classList.toggle('main-menu__burger_active');
      e.target.nextElementSibling.classList.toggle('mobile-visible');
    })
  }
});

window.addEventListener('load', () => {
  // слайдеры товаров
  const productsSliders = document.querySelectorAll('.products-slider .swiper-container');
  if (productsSliders) {
    [...productsSliders].map(slider => new Swiper(slider, {
      loop: true,
      slidesPerView: 4,
      spaceBetween: 22,
      slidesPerGroup: 4,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        1180: {
          slidesPerView: 4,
        },
        1000: {
          slidesPerView: 3,
        },
        840: {
          slidesPerView: 2,
        },
        640: {
          slidesPerView: 3,
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 22,
        },
        280: {
          slidesPerView: 1,
          spaceBetween: 0,
        }
      }
    }));
  }

  // главный слайдер
  const mainSlider = document.querySelector('.main-slider .swiper-container');
  if (mainSlider) {
    const swiperMainSlider = new Swiper(mainSlider, {
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    })
  }

  // Слайдер партнеров
  const suppliersSlider = document.querySelector('.suppliers-slider .swiper-container');
  if (suppliersSlider) {
    const swiperSuppliersSlider = new Swiper(suppliersSlider, {
      loop: true,
      freeMode: true,
      slidesPerView: 5,
      spaceBetween: 10,
      navigation: {
        nextEl: '.suppliers-slider__control_next',
        prevEl: '.suppliers-slider__control_prev',
      },
      breakpoints: {
        1000: {
          slidesPerView: 5,
        },
        640: {
          slidesPerView: 3,
        },
        380: {
          slidesPerView: 2,
        },
        280: {
          slidesPerView: 1,
        }
      }
    })
  }

  // label в форме
  const suitableInputs = ['text', 'tel', 'email', 'password'];
  const form = document.querySelectorAll('.form');
  if (form) {
    const inputs = document.querySelectorAll('.form-item input, .form-item textarea');
    [...inputs].map(input => {
      input.addEventListener('focus', (e) => {
        // проверка на textarea или input
        if (e.target.tagName.toLowerCase() === 'textarea'
          || suitableInputs.includes(e.target.getAttribute('type').toLowerCase())) {
          const formItem = e.target.parentNode;
          formItem.classList.add('form-item_active');
        }
        return false;
      });
      input.addEventListener('blur', (e) => {
        if (e.target.value.trim(' ') === '') {
          const formItem = e.target.parentNode;
          formItem.classList.remove('form-item_active');
        }
      })
    })
  }

  // modal
  const modalLinks = document.querySelectorAll('.modal-link');
  if (modalLinks) {
    [...modalLinks].map(link => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        const targetSelector = e.target.getAttribute('href') || e.target.closest('.modal-link').getAttribute('href');
        const target = document.querySelector(targetSelector);
        if (target) {
          target.classList.add('modal_shown');
          document.body.classList.add('scroll-lock');
        }
        return false;
      })
    });
    const closeBtn = document.querySelectorAll('.modal__close');
    [...closeBtn].map(close => {
      close.addEventListener('click', (e) => {
        e.preventDefault();
        const currentModals = document.querySelectorAll('.modal_shown');
        [...currentModals].map(modal => {
          modal.classList.remove('modal_shown');
          document.body.classList.remove('scroll-lock');
        })
      })
    })
  }

  // Адаптив сайдбара
  const sidebarBtn = document.getElementById('catalog-button');
  if (sidebarBtn) {
    sidebarBtn.addEventListener('click', (e) => {
      e.preventDefault();
      const sidebar = document.getElementById('sidebar');
      sidebar.classList.toggle('sidebar_mobile-visible');
      sidebarBtn.classList.toggle('catalog-button_active');
      return false;
    })
  }
});